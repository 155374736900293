import React from 'react';
import GoogleMap from '../../components/googleMap/googleMap.js';
import './addListing.css';
import PaginationButtonB from '../../components/paginationButtons/pagignationLeft';
import '@fortawesome/fontawesome-free/css/all.css';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const AddListing2 = () => {
  const navigate = useNavigate();

  const handleNextButtonClick = () => {
 
    navigate('/addListing3');
  
  };
  return (
    <div className="main-container" >
      <div className="header-container">
            <PaginationButtonB />
            <p >Add Listing </p>
        </div>
      <GoogleMap />

      <div className="header-container2">
            <PaginationButtonB />
            <div className="button-container">
            
            <button className="button" onClick={handleNextButtonClick}>Next</button>
            
            </div>
        </div>
    </div>
  );
};

export default AddListing2;

import styles from "./styles/index.module.css";

export default function About() {
  return (
    <div>
      <p className={styles.innerParagraphAboutText}>
        {" "}
        We are a Lusaka-based proptech company with the goal of revolutionizing
        the real estate industry by leveraging cutting-edge technology to
        develop smooth processes for resolving real estate challenges. Our
        products and services are created with real estate professionals, home
        owners, investors, and tenants in mind. Through our website, we
        currently assist our clients with renting, buying, and selling real
        estate effortlessly. We also put them in touch with local affiliate
        specialists who provide professional counsel on their journey to
        discover their new home.{" "}
      </p>

      <p className={styles.headerAboutText}> A real Estate Company with a Difference </p>

      <p className={styles.innerParagraphAboutText}>
        {" "}
        Our in-house tech team enables us to continuously develop products that
        help our customers meet their goals. We have continued to use and adapt
        innovative technology based solutions as we serve our vast client base
        and partners. We believe that technology should make processes easier
        and more efficient without replacing the need for real estate
        professionals who play a vital role in providing counsel to our clients.{" "}
      </p>

      <p className={styles.headerAboutText}> For Renters </p>

      <p className={styles.innerParagraphAboutText}>
        {" "}
        We help you find the perfect home for rent through our website which
        boasts many listings for different market segments. We stay updated with
        the latest available units through our partner agents and home owners
        who list with us. Our strategy and business model has made it easier to
        find your next home with the support of a real estate professional on
        ground - hassle free. Through the platform renters can search, book
        appointments, apply, and make an offer.{" "}
      </p>

      <p className={styles.headerAboutText}> For Buyers </p>

      <p className={styles.innerParagraphAboutText}>
        {" "}
        Buying property can be very complex, tedious and time consuming. We are
        working towards digitizing the entire process from searching and
        securing financing to making an offer and closing the purchase. Like
        renters, buyers can skim through our listings to find houses for sale
        and close on the houses they like with a designated real estate
        professional to help.{" "}
      </p>

      <p className={styles.headerAboutText}> For Sellers </p>

      <p className={styles.innerParagraphAboutText}>
        {" "}
        We help you sell your property or put it up for rent through our
        listings platform. Sellers can get started by getting a property
        valuation to see the profit potential of selling. We also connect you to
        market experts such as valuation surveyors, property managers, and real
        estate agents who can do a comparative analysis and consult on home
        improvements that can help maximize value.{" "}
      </p>

      <p className={styles.headerAboutText}> Our Partners </p>

      <p className={styles.innerParagraphAboutText}>
        {" "}
        We partner with real estate professionals and make them part of our
        agent matching system aimed at generating leads, networking, and
        boosting sales. Our heavily visited website makes it possible for our
        partners to list properties and close on them faster than traditional
        methods. We intend on being the go to platform for all things real
        estate and thus our partners play a pivotal role in helping us achieve
        this goal. We have partnered with top valuation firms, real estate
        brokerage firms, banks, real estate agents, and property managers who
        help make everything possible.{" "}
      </p>
    </div>
  );
}

import React, { useState } from 'react';

const NumberSpinner = () => {
  const [value, setValue] = useState(1);

  const incrementValue = () => {
    setValue(value + 1);
  };

  const decrementValue = () => {
    if (value > 1) {
      setValue(value - 1);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', backgroundColor:'#eeeeee', borderRadius:'15px', marginBottom:'10px', marginTop:'10px' }}>
      <span style={{ marginRight: '8px', fontSize:'small',  padding: '0 10px' }}> Bedroom</span> &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <div style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
        <button onClick={decrementValue}>-</button>
        <span style={{ margin: '0 4px' }}>{value}</span>
        <button onClick={incrementValue}>+</button>
      </div>
    </div>
  );
};

export default NumberSpinner;

import React from "react";
import styles from "./styles/imageSlider.module.css";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const Slideshow = ({ propertyImageUrls }) => {
  return (
    <div className="slide-container">
      <Fade>
        {propertyImageUrls.map((elementImage, index) => {
          return (
            <div className="each-fade" key={index}>
              <div className={styles.imageContainer}>
                <img src={elementImage} width={400} height={300} />
              </div>
            </div>
          );
        })}
      </Fade>
    </div>
  );
};

export default Slideshow;

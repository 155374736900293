import React from 'react';
import './googleMap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { blue } from '@mui/material/colors';

const GoogleMap = () => {
  return (
    <div className="map-container">
    
    <div className='location-container'>
    <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" className='icon'  />
    <p className='head'>  Where is the <strong>location?</strong></p>
    </div>
    <div>
    <iframe
      title="Google Map"
      width="600"
      height="450"
      frameBorder="0"
      style={{ border: 0, borderRadius: '10px'}}
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.1292376937596!2d-122.08446968475527!3d37.422207779763484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f80d30a036be5%3A0xe3e8d02d06770bb9!2sGolden%20Gate%20Bridge!5e0!3m2!1sen!2sus!4v1635467543775!5m2!1sen!2sus"
      allowFullScreen
    ></iframe>
    </div>
    <br></br>
    <div className='all'>
      <p className='p'>Select on the map</p>
    </div>
  </div>
  );
};

export default GoogleMap;

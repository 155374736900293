import React from 'react';
import './PtT.css';


class ProductTourT extends React.Component {
    render() {
      return (
        <div>
          
          <nav className="nav">
            <div className="logo">
                <img src="image/loopholeLogoAsset5x3.png" alt="Logo" />
            </div>
            
            <button className= "btn">Skip</button>
        </nav>
        <div>
          <h1><strong> Manage your property</strong></h1>
        </div>
        <div>
          <h1 className='w'>from anywhere in the world</h1>
        </div>
        <div>
          <p>Our modern real estate platform allows you to manage <br></br>
          your property with ease from any location in the world.
          </p>
        </div>
        <div className='image-container'>
          <img src='image/log.svg' className='hpic'></img>

        </div>
        <div className="button-container">
    <button className="button">Next</button>
  
  </div>
        </div>
      );
    }
  }
  
  export default ProductTourT;
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from './error-page';
import Home from "./routes/home";
import SaleslListings from "./routes/salesListings";
import RentalListings from "./routes/rentalListings";
import ContactUs from "./routes/contactUs";
import About from "./routes/about";
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Container } from '@mui/system';
import Navbar from './components/nav/nav';
import AuthForm from './routes/authForms/AuthForm';
import ProductTourF from './routes/productTour1/ProductTourF';
import ProductTourS from './routes/productTour2/Pt';
import ProductTourT from './routes/productTour3/PtT';
import ImageUpload from './routes/addListing/addListing1';
import AddListing2 from './routes/addListing/addListing2';
import AddListing3 from './routes/addListing/addListing3';
import AddListing4 from './routes/addListing/addListing4';
import Profile from './routes/profile/profile';
import Dashboard from './routes/LandingPage/Lp';

const router = createBrowserRouter([
  {
    element: <Navbar />,
    children: [
      { 
        path: "/",
        element: <Home />,
        errorElement: <ErrorPage />,
      },
      { 
        path: "/salesListings",
        element: <SaleslListings />,
        errorElement: <ErrorPage />,
      },
      { 
        path: "/rentalListings",
        element: <RentalListings />,
        errorElement: <ErrorPage />,
      },
      { 
        path: "/rentalListings/rentalAppointment/:propertyId",
        element: <RentalListings />,
        errorElement: <ErrorPage />,
      },
      { 
        path: "/contactUs",
        element: <ContactUs />,
        errorElement: <ErrorPage />,
      },
      { 
        path: "/about",
        element: <About />,
        errorElement: <ErrorPage />,
      },
      { 
        path: '/authForm',
        element: <AuthForm />,
        errorElement: <ErrorPage />,
      },
      
    ]
    
  },
  { 
    path: '/productTourF',
    element: <ProductTourF />,
    errorElement: <ErrorPage />,
  },
  { 
    path: '/productTourS',
    element: <ProductTourS />,
    errorElement: <ErrorPage />,
  },
  { 
    path: '/productTourT',
    element: <ProductTourT />,
    errorElement: <ErrorPage />,
  },
  { 
    path: '/addListing1',
    element: <ImageUpload />,
    errorElement: <ErrorPage />,
  },
  { 
    path: '/addListing2',
    element: <AddListing2 />,
    errorElement: <ErrorPage />,
  },
  { 
    path: '/addListing3',
    element: <AddListing3 />,
    errorElement: <ErrorPage />,
  },
  { 
    path: '/addListing4',
    element: <AddListing4 />,
    errorElement: <ErrorPage />,
  },
  { 
    path: '/dashboard',
    element: <Dashboard />,
    errorElement: <ErrorPage />,
  },
  { 
    path: '/profile',
    element: <Profile />,
    errorElement: <ErrorPage />,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Container>
      <RouterProvider router={router} />
    </Container>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import * as React from "react";
import styles from "./styles/index.module.css";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import homeProspectiveImage from '../../assets/images/home/firstHomeImageProspective.jpeg';
import realEstateAboutHomeImage from '../../assets/images/home/realEstateAboutHomeImage.jpg';
import Grid from "@mui/material/Unstable_Grid2";

export default function Home() {
  return(
  <>
        <Grid className={styles.homePageGridContainer} container>
          <Grid item xs={12} sm={6} order={{ xs: 2, sm: 1 }}>
            <div className={styles.gridTextContainer}>
              <p className={styles.homeLoopholeHeaderText}>
                {" "}
                Your <span className={styles.homeLoopholeDreamText}>
                  {" "}
                  Dream{" "}
                </span>{" "}
              </p>
              <p className={styles.homeLoopholeHeaderText}> Home Awaits </p>
              <p className={styles.homeLoopholeMainText}>
                {" "}
                Discover your dream home, sell, and manage your property online!
              </p>
              <Link to="/contactUs">
                <button
                  name="button"
                  className={styles.viewListingsButton}
                  style={{ marginLeft: "1.8rem" }}
                >
                  <span className={styles.buttonFont}> Get In Touch </span>
                </button>
              </Link>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            order={{ xs: 1, sm: 2 }}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Box >
              <img
                alt="prospective house"
                src={homeProspectiveImage}
                className={styles.prospectiveHouseImage}
              />
            </Box>
            {/* </div> */}
          </Grid>
        </Grid>

        <FeaturedRentalListings />

        <FeaturedSaleslListings />

        <div className={styles.borderPageDivider} />

        <Grid className={styles.homePageAboutUsGridContainer} container px={3}>
          <Grid item xs={12} sm={6}>
            <img
              alt="prospective house"
              src={realEstateAboutHomeImage}
              layout="responsive"
              className={styles.prospectiveHouseImage}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className={styles.gridTextAboutContainer}>
              <p className={styles.homeLoopholeAboutHeaderTitle}> ABOUT US </p>
              <p className={styles.homeLoopholeAboutHeaderText}>
                A Real Estate Company with a Difference!
              </p>
              <p className={styles.homeLoopholeMainText}>
                {" "}
                Welcome to Loophole, where we're reshaping real estate through cutting-edge technology.
                Our platform caters to professionals, homeowners, investors, and tenants, offering a seamless
                experience in renting, buying, and selling. Powered by our in-house tech team, we prioritize
                simplicity without compromising the crucial guidance of real estate experts.
                For renters, finding the perfect home is easy, with hassle-free searches, bookings, and offers
                supported by on-ground professionals. Buyers enjoy a digitized process from search to closing,
                while sellers can effortlessly list their properties and access valuation experts for maximizing
                value.
                In collaboration with top valuation firms, real estate brokerages, banks, agents, and property
                managers, we aim to be the go-to platform for all real estate needs, making transactions faster
                and more efficient for everyone involved. Welcome to a new era in real estate with Loophole

              </p>
              <Link to="./about">
                <button name="button" className={styles.aboutUsButton}>
                  <span className={styles.aboutUsbuttonFont}> About Us </span>
                </button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </>
      )
}


const FeaturedRentalListings = () => {
  const [scrollIndex, setScrollIndex] = useState(0);
  const [maxScrollIndex, setMaxScrollIndex] = useState(0);

  useEffect(() => {
    const totalCards = 3; // Number of cards to display at a time
    const maxIndex = 10; // Assuming there are 10 cards in total
    setMaxScrollIndex(Math.max(0, maxIndex - totalCards));
  }, []);

  const handlePrevClick = () => {
    setScrollIndex((prevIndex) => Math.max(0, prevIndex - 1));
  };

  const handleNextClick = () => {
    setScrollIndex((prevIndex) => Math.min(maxScrollIndex, prevIndex + 1));
  };

  return (
    <>
      <p className={styles.homeLoopholeHeaderText}>
        Featured Properties for Rent
      </p>

      <div className={styles.carouselContainer}>
        <div className={styles.carousel}>
          <button className={styles.navButton} onClick={handlePrevClick}>&lt;</button>
          <div className={styles.cardWrapper}>
            <Grid container spacing={2}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (
                <Grid key={index} item xs={12} sm={6} md={4} style={{ display: index >= scrollIndex && index < scrollIndex + 3 ? 'block' : 'none' }}>
                  <Card className={styles.card}>
                    <CardMedia
                      component="img"
                      height="180"
                      image={`https://le-cdn.hibuwebsites.com/4289fde35330438e800899f332da9581/dms3rep/multi/opt/h4-396w.jpg`}
                      alt={`Property ${index}`}
                    />
                    <CardContent>
                      <p className={styles.listingsCardPriceText}>ZMW {index * 1000}/pm</p>
                      <div>
                        <p className={styles.listingsCardPropertyDetails}>
                          <span className={styles.listingsCardPropertyDetailsFontBold}>- {index + 1} bedrooms,</span>
                          <span className={styles.listingsCardPropertyDetailsFontBold}> 2 bathrooms</span>
                        </p>
                        <p className={styles.listingsCardPropertyDetails}>
                          <span className={styles.listingsCardPropertyDetailsFontBold}>- {index * 100} m2 land</span>
                        </p>
                        <p className={`${styles.listingsCardPropertyDetails} ${styles.listingsCardPropertyDetailsFontBold}`}>
                          - Location {index}
                        </p>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
          <button className={styles.navButton} onClick={handleNextClick}>&gt;</button>
        </div>
      </div>

      <button
        name="button"
        className={styles.viewListingsButton}
        style={{ marginLeft: "1.8rem" }}
      >
       
        <Link to="/rentalListings" style={{ textDecoration: "none" }}>
          <span className={styles.buttonFont}> View Listings </span>
        </Link>
      </button>
    </>
  )
}



    

const FeaturedSaleslListings = () => {
  return (
    <>
    <p className={styles.homeLoopholeHeaderText}>
      Featured Properties for Sale 
    </p>

    <div className={styles.iconGridContainer}>
      <Grid container sx={{ my: 3 }}>
        <Grid item xs={12} sm={6} md={4} sx={{ p: 2 }}>
          <Card
            sx={{
              maxWidth: 345,
              border: "2px solid #a8a8a8",
              borderRadius: "40px",
            }}
          >
            <CardMedia
              sx={{ height: 180 }}
              image="https://le-cdn.hibuwebsites.com/4289fde35330438e800899f332da9581/dms3rep/multi/opt/h4-396w.jpg"
              title="green iguana"
            />
            <CardContent>
              <p className={styles.listingsCardPriceText}>ZMW 4,500/pm</p>
              <div>
                <p className={styles.listingsCardPropertyDetails}>
                  <span
                    className={styles.listingsCardPropertyDetailsFontBold}
                  >
                    - 4
                  </span>{" "}
                  bedrooms,{" "}
                  <span
                    className={styles.listingsCardPropertyDetailsFontBold}
                  >
                    2
                  </span>{" "}
                  bathrooms
                </p>
                <p className={styles.listingsCardPropertyDetails}>
                  <span
                    className={styles.listingsCardPropertyDetailsFontBold}
                  >
                    {" "}
                    - 240
                  </span>
                  m2 land
                </p>
                <p
                  className={`${styles.listingsCardPropertyDetails} ${styles.listingsCardPropertyDetailsFontBold}`}
                >
                  {" "}
                  - Kabulonga, Lusaka{" "}
                </p>
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} sx={{ p: 2 }}>
          <Card
            sx={{
              maxWidth: 345,
              border: "2px solid #a8a8a8",
              borderRadius: "40px",
            }}
          >
            <CardMedia
              sx={{ height: 180 }}
              image="https://le-cdn.hibuwebsites.com/4289fde35330438e800899f332da9581/dms3rep/multi/opt/h4-396w.jpg"
              title="green iguana"
            />
            <CardContent>
              <p className={styles.listingsCardPriceText}>ZMW 4,300/pm</p>
              <div>
                <p className={styles.listingsCardPropertyDetails}>
                  <span
                    className={styles.listingsCardPropertyDetailsFontBold}
                  >
                    - 3
                  </span>{" "}
                  bedrooms,{" "}
                  <span
                    className={styles.listingsCardPropertyDetailsFontBold}
                  >
                    2
                  </span>{" "}
                  bathrooms
                </p>
                <p className={styles.listingsCardPropertyDetails}>
                  <span
                    className={styles.listingsCardPropertyDetailsFontBold}
                  >
                    {" "}
                    - 270
                  </span>
                  m2 land
                </p>
                <p
                  className={`${styles.listingsCardPropertyDetails} ${styles.listingsCardPropertyDetailsFontBold}`}
                >
                  {" "}
                  - Woodlands, Lusaka{" "}
                </p>
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} sx={{ p: 2 }}>
          <Card
            sx={{
              maxWidth: 345,
              border: "2px solid #a8a8a8",
              borderRadius: "40px",
            }}
          >
            <CardMedia
              sx={{ height: 180 }}
              image="https://le-cdn.hibuwebsites.com/4289fde35330438e800899f332da9581/dms3rep/multi/opt/h4-396w.jpg"
              title="green iguana"
            />
            <CardContent>
              <p className={styles.listingsCardPriceText}>ZMW 5,500/pm</p>
              <div>
                <p className={styles.listingsCardPropertyDetails}>
                  <span
                    className={styles.listingsCardPropertyDetailsFontBold}
                  >
                    - 4
                  </span>{" "}
                  bedrooms,{" "}
                  <span
                    className={styles.listingsCardPropertyDetailsFontBold}
                  >
                    2
                  </span>{" "}
                  bathrooms
                </p>
                <p className={styles.listingsCardPropertyDetails}>
                  <span
                    className={styles.listingsCardPropertyDetailsFontBold}
                  >
                    {" "}
                    - 240
                  </span>
                  m2 land
                </p>
                <p
                  className={`${styles.listingsCardPropertyDetails} ${styles.listingsCardPropertyDetailsFontBold}`}
                >
                  {" "}
                  - Kansenshi, Ndola{" "}
                </p>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <button
        name="button"
        className={styles.viewListingsButton}
        style={{ marginLeft: "1.8rem" }}
      >
        <Link to="/rentalListings" style={{ textDecoration: "none" }}>
          <span className={styles.buttonFont}> View Listings </span>
        </Link>
      </button>

    </div>
    </>
  )
}
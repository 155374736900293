import React from 'react';
import './ProductTourF.css';
import '@fortawesome/fontawesome-free/css/all.css';


class ProductTourF extends React.Component {
  render() {
    return (
      <div className='entire-section'>
        
        
        <div className="logo-container">
    <img src="image/loopholeLogoAsset4x3.png" alt="Logo" className='pic'></img>
   
    <p class="logo-text">The ultimate real estate platform</p>
  </div>

  <div className="button-container">
    <button className="button">Get Started</button>
  
  </div>
      </div>
    );
  }
}

export default ProductTourF;

import React, { useState } from 'react';
import './Lp.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faBell } from '@fortawesome/free-solid-svg-icons'; 
import SearchComponent from '../../components/search/search';
import ToggleButtonGroup2 from '../../components/toggleButtonSet/toggleButton2';
import CardComponent from '../../components/cards/cards';
import HorizontalForm from '../../components/Himages/HorizontalForm';


const Dashboard = () => {
  
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
  
  const [isNotificationOn, setIsNotificationOn] = useState(false);

 
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  
  const toggleNotification = () => {
    setIsNotificationOn(!isNotificationOn);
  };

  return (
    <div >
     <div className="header-container">

     
      <button className="dropdown-btn" onClick={toggleDropdown}>
        <i className="fa fa-map-marker" style={{color:"grey"}} aria-hidden="true"></i> Lusaka Zambia <i className="fa fa-caret-down dropdown-icon" aria-hidden="true"></i>
      </button>
      
      {isDropdownOpen && (
          <div className="dropdown-menu">
           
            <div className="dropdown-item">Copperbelt</div>
            <div className="dropdown-item">Siavonga</div>
          </div>
        )}
      
    
      <div className="spacer"></div>
   
      <div className={`icon-container ${isNotificationOn ? 'active' : ''}`} onClick={toggleNotification}>
        <FontAwesomeIcon icon={faBell} className="icon" />
      </div>
     
      <div className="icon-container">
        <img src='image/couch.jpg' alt="Profile" className="icon" style={{borderRadius:'50%'}}/>
      </div>
      </div>

      <div style={{marginTop:'80px'}}>
      <h3>Quick Search</h3>
        <HorizontalForm />
      </div>
      
      <div className='left-content-right-image'>
     <div className="left-content">
      <h2  className="greeting" style={{bottom:"100px", color:'#004170', fontSize:'big', marginTop:'60px'}}>Hey, Bupe! Let's start exploring</h2>
      <SearchComponent />
     <ToggleButtonGroup2 />
     </div>

     <div className="spacer"></div>

     <div className="right-image">
        <img src='image/house.jpg' alt="Right Image" />
     </div>
     </div>

     <div style={{margin:'100px 20px', backgroundColor:'#eee'}}>
        <CardComponent />
     </div>
     </div>
  );
};

export default Dashboard;

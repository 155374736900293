import React, { useState } from 'react';
import './toggleButton.css'; 

const ToggleButtonGroup4 = () => {
  const [selectedButtons, setSelectedButtons] = useState([]);

  const handleButtonClick = (buttonId) => {
    
    if (selectedButtons.includes(buttonId)) {
    
      setSelectedButtons(selectedButtons.filter((id) => id !== buttonId));
    } else {
     
      setSelectedButtons([...selectedButtons, buttonId]);
    }
  };

  return (
    <div className="toggle-button-group">
      <button
        className={selectedButtons.includes(1) ? 'selected' : ''}
        onClick={() => handleButtonClick(1)}
      >
        1+
      </button>
      <button
        className={selectedButtons.includes(2) ? 'selected' : ''}
        onClick={() => handleButtonClick(2)}
      >
        3+
      </button>
      <button
        className={selectedButtons.includes(3) ? 'selected' : ''}
        onClick={() => handleButtonClick(3)}
      >
        5+
      </button>
      <button
        className={selectedButtons.includes(4) ? 'selected' : ''}
        onClick={() => handleButtonClick(4)}
      >
        8 +
      </button>
      <button
        className={selectedButtons.includes(5) ? 'selected' : ''}
        onClick={() => handleButtonClick(5)}
      >
        10 +
      </button>
    </div>
  );
};

export default ToggleButtonGroup4;

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchComponent = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    
    console.log('Searching for:', searchTerm);
    
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ position: 'relative', marginRight: '8px' }}>
        <FontAwesomeIcon icon={faSearch} style={{ position: 'absolute', left: '8px', top: '50%', transform: 'translateY(-50%)', color: '#aaa', }} />
        <input
          type="text"
          placeholder="Search house, apartment, etc"
          value={searchTerm}
          onChange={handleInputChange}
          style = {{ paddingLeft: '28px', border: 'none', outline: 'none' }}
        />
      
      <button onClick={handleSearch}  style={{ background: 'none', border: 'none', cursor: 'pointer',backgroundColor:'#ccc', top: '30px' }}>Search</button>
    </div>
    </div>
  );
};

export default SearchComponent;

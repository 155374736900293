import { Container, Grid } from "@mui/material";
import RentalPropertyCard from "../../components/rentalListings/rentalPropertyCard.js";
import { useEffect, useState } from "react";
import { fetchPropertiesForSale } from "../../services/propertiesService.js";
// import useLoadingState from "../../components/utils/useLoadingState.js";
// import { Audio } from "react-loader-spinner";

import styles from "./styles/index.module.css";

export default function SaleslListings() {
  const [showMoreBoolean, setShowMoreBoolean] = useState(false);
  const [listingsData, setListingsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchSalesListingsData = async () => {
      try {
        setIsLoading(true)
       const data = await fetchPropertiesForSale()
        setListingsData(data || []);
        setIsLoading(false)
      } catch {
        console.log("error");
      }
    };
    fetchSalesListingsData();
  }, []);

  const showMoreComponentOnClick = () => {
    setShowMoreBoolean(!showMoreBoolean);
  };
  
  console.log("listings data::", listingsData)
  if (
    !listingsData ||
    listingsData == null ||
    listingsData === undefined ||
    listingsData?.legnth <= 0
  ) {
    return <div>Fetching Data...</div>;
  }

    return (
      <>
        <Container>
          <div>
            <p className={styles.rentalListingsMainText}>
              Sales{" "}
              <span className={styles.rentalListingsOtherColour}>Listings </span>
            </p>
          </div>
                <Grid container>
                  {listingsData.map((property) => (
                    <RentalPropertyCard
                      key={property.propertyId}
                      propertyDetails={{
                        propertyId: property.id,
                        houseNumberOfBedrooms: property.house_number_of_bedrooms,
                        price: property.price,
                        houseNumberOfBathrooms: property.house_number_of_bathrooms,
                        houseSquareArea: property.house_square_area,
                        houseLocation: property.house_location,
                        housePicturesUrls: property.house_pictures_urls,
                        housePropertyDetails: property.house_property_details,
                      }}
                      onClickMoreFunction={showMoreComponentOnClick}
                      showMoreBoolean={showMoreBoolean}
                      setShowMoreBoolean={setShowMoreBoolean}
                      cardImageSource={property.house_pictures_urls[0]}
                    />
                  ))}
                </Grid>
        </Container>
      </>
    );
  }

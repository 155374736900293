import { Container, Grid } from "@mui/material";
import RentalPropertyCard from "../../components/rentalListings/rentalPropertyCard";
import { useEffect, useState } from "react";
// import useLoadingState from "../../components/utils/useLoadingState.js";
// import { Audio } from "react-loader-spinner";

import styles from "./styles/index.module.css";
import { fetchPropertiesForRent } from "../../services/propertiesService";

function RentalListings() {
  const [showMoreBoolean, setShowMoreBoolean] = useState(false);
  const [listingsData, setListingsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const showMoreComponentOnClick = () => {
    setShowMoreBoolean(!showMoreBoolean);
  };

  useEffect(() => {
    const fetchSalesListingsData = async () => {
      try {
        setIsLoading(true)
        const data = await fetchPropertiesForRent();
        setListingsData(data || {});
        setIsLoading(false)
      } catch (error) {
        console.log("error", error.message);
      }
    };
    fetchSalesListingsData();
  }, []);

  console.log(
    "🚀 ~ file: index.js:22 ~ RentalListings ~ listingsData",
    listingsData
  );

  if (
    !listingsData ||
    listingsData == null ||
    listingsData === undefined ||
    listingsData?.legnth <= 0
  ) {
    return <div>Fetching Data...</div>;
  }

  return (
    <>
      <Container>
        <div>
          <p className={styles.rentalListingsMainText}>
            Rental{" "}
            <span className={styles.rentalListingsOtherColour}>Listings </span>
          </p>
        </div>

        {/* <Box sx={{ height: '10vh', border: '1px solid black' }} >
          <div>
            Search bar
            </div>
        </Box> */}

        <Grid container>
          {listingsData.map((property) => {
            return (
              <RentalPropertyCard
                key={property.propertyId}
                propertyDetails={{
                  propertyId: property.id,
                  houseNumberOfBedrooms: property.house_number_of_bedrooms,
                  price: property.price,
                  houseNumberOfBathrooms: property.house_number_of_bathrooms,
                  houseSquareArea: property.house_square_area,
                  houseLocation: property.house_location,
                  housePicturesUrls: property.house_pictures_urls,
                  housePropertyDetails: property.house_property_details,
                }}
                onClickMoreFunction={showMoreComponentOnClick}
                showMoreBoolean={showMoreBoolean}
                setShowMoreBoolean={setShowMoreBoolean}
                cardImageSource={property.house_pictures_urls[0]}
              />
            );
          })}
        </Grid>
      </Container>
    </>
  );
}

export default RentalListings;

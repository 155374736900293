import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './pagignation.css';

const PaginationButtonB = () => {
  const navigate = useNavigate();

  const goToPreviousPage = () => {
    navigate(-1); 
  };

  return (
    <button className="pagination-button" onClick={goToPreviousPage}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </button>
  );
};

export default PaginationButtonB;


import React from 'react';
import './cards.css';

const CardComponent = () => {
  return (
    <div>
      <div className="card">
        <div className="image">
          <img src="image/couch.jpg" />
        </div>
        <div className="title">
          <h1>House</h1>
        </div>
        <div className="des">
          <p>New Kasama, 3 bedrooms</p>
          <button>Details</button>
        </div>
      </div>

      <div className="card">
        <div className="image">
          <img src="image/house.jpg" />
        </div>
        <div className="title">
          <h1>Lolani Flats</h1>
        </div>
        <div className="des">
          <p>Chalala Apex, 2 bedrooms</p>
          <button>Details</button>
        </div>
      </div>
      <div className="card">
        <div className="image">
          <img src="image/couch.jpg" />
        </div>
        <div className="title">
          <h1>Kabulonga Villa</h1>
        </div>
        <div className="des">
          <p>Kabulonga, 4 bedrooms</p>
          <button>Details</button>
        </div>
      </div>

      <div className="card">
        <div className="image">
          <img src="image/house.jpg" />
        </div>
        <div className="title">
          <h1>parklands</h1>
        </div>
        <div className="des">
          <p>Parklands, 3 bedrooms</p>
          <button>Details</button>
        </div>
      </div>

    </div>
  );
};

export default CardComponent;

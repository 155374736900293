import React from 'react';
import './HorizontalForm.css'; 

const HorizontalForm = () => {
  return (
    <div className="horizontal-form">
      <input type="text" placeholder="Key Word" className="text-input" />
      <select className="dropdown" >
      <option value="" disabled selected>Property Type</option>
        <option value="option1">House</option>
        <option value="option2">Apartment </option>
        <option value="option3">Hotel</option>
      </select>
      <select className="dropdown" >
      <option value="" disabled selected>Location</option>
        <option value="optionA">Woodlands</option>
        <option value="optionB">Chalala</option>
        <option value="optionC">New Kasama</option>
      </select>
      <button className='B'>Search</button>
    </div>
  );
};

export default HorizontalForm;

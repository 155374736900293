import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.css';
import React, { useState } from 'react';
import './addListing.css';
import PaginationButtonB from '../../components/paginationButtons/pagignationLeft';
import { useNavigate } from 'react-router-dom';

const ImageUpload = () => {
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (event) => {
    const files = event.target.files;
    const selectedImagesArray = Array.from(files);
    setSelectedImages(selectedImagesArray);
  };
  const navigate = useNavigate();

  const handleNextButtonClick = () => {
 
    navigate('/addListing2');
  
  };

  return (
    <div>
        <div className="header-container">
            <PaginationButtonB />
            <p >Add Listing </p>
        </div>
        <div className='sh'style={{ marginLeft: '5px' }}>
        <p className='par'>Add <strong >photos</strong> to your listing</p>
        </div>
      
      <div className="image-grid">
      <div class="image-row">
        <img src="image/couch.jpg" alt="Image 1" className="uploadpic"></img>
        <img src="image/house.jpg" alt="Image 2" className="uploadpic"></img>
    </div>
  <div class="image-row">
        <img src="image/couch.jpg" alt="Image 3" className="uploadpic"></img>
        <img src="image/house.jpg" alt="Image 4" className="uploadpic"></img>
  </div>
      </div>
      <div className="header-container2">
            <PaginationButtonB />
            <div className="button-container">
                <button className="button"  onClick={handleNextButtonClick}>Next</button>
            </div>
        </div>
    </div>
  );
};

export default ImageUpload;

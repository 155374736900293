async function fetchPropertiesForSale() {
  try {
    const response = await fetch(`${process.env.REACT_APP_LOOPHOLE_API}/properties?for_sale=true`);
    console.log("response in properties service", response)
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data
  }  catch (error) {
    console.log("Error fetching properties", error)
  }
}

async function fetchPropertiesForRent() {
  try {
    const response = await fetch(`${process.env.REACT_APP_LOOPHOLE_API}/properties?for_rent=true`);
    console.log("response in properties service", response)
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data
  }  catch (error) {
    console.log("Error fetching properties", error)
  }
}
export { fetchPropertiesForSale, fetchPropertiesForRent }
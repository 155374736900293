import React from 'react';
import './ProductTourS.css';


class ProductTourS extends React.Component {
    render() {
      return (
        <div>
          
          <nav className="nav">
            <div className="logo">
                <img src="image/loopholeLogoAsset5x3.png" alt="Logo" />
            </div>
            
            <button className= "btn">Skip</button>
        </nav>
        <div>
          <h1><strong> the best house for you</strong></h1>
        </div>
        <div>
          <h1 className='w'>within your budget</h1>
        </div>
        <div>
          <p>From cozy appartments to luxurious villas, our platform <br></br>
            offers a vast array listings guaranteed to facinate you.
          </p>
        </div>
        <div className='image-container'>
          <img src='image/register.svg' className='hpic'></img>

        </div>
        <div className="button-container">
    <button className="button">Next</button>
  
  </div>
        </div>
      );
    }
  }
  
  export default ProductTourS;
import { Link } from 'react-router-dom';
import loophHoleLogo from '../../assets/images/nav/loopholeLogoAsset5x3.png';
import closeMobileMenuIcon from '../../assets/images/nav/closeMobileMenu.png';
import menuMobileIcon from '../../assets/images/nav/mobileMenuIcon.png';
import { Outlet } from 'react-router-dom';
import styles from './styles/index.module.css'
import { useState } from "react";

export default function Navbar() {
  const [showMobileMenu, setShowMobileMenu ] = useState(false);  
  return (
    <>
     <div className={styles.navContainer}>
      <Link to="/">
        <div className={styles.showFullLogo}>
          <img
            alt="loophole logo"
            src={loophHoleLogo}
            className={styles.desktopImageLogo}
            width={170} 
            height={40}
          />
        </div>
      </Link>
      <div className={styles.showMobileLogo}>
        <button 
          name="button"
          className={styles.mobileMenuButton} 
          onClick={() => {
            setShowMobileMenu((showMobileMenu) => !showMobileMenu)
          }} 
        >
          <img
            alt="loophole logo"
            src={showMobileMenu ? closeMobileMenuIcon : menuMobileIcon}
            className={styles.hamburgerIconImage}
            width={40}
            height={40}
          />
        </button>      
      </div>
      
        { showMobileMenu && 
          <div className={styles.mobileLinksContainer}>
            <Link to="/salesListings" className={styles.navLinks}>
              Buy
            </Link>
            <Link to="/rentalListings" className={styles.navLinks}>
              Rent
            </Link>
            <Link to="/contactUs" className={styles.navLinks}>
              Consult
            </Link>
            <Link to="/about" className={[styles.navLinks, styles.navLinksLast].join(' ')}>
              About Us
            </Link>
          </div>
        }

          <div className={styles.linksContainerDesktop}>
            <Link to="/salesListings" className={styles.navLinks}>
              Buy
            </Link>
            <Link to="/rentalListings" className={styles.navLinks}>
              Rent
            </Link>
            <Link to="/contactUs" className={styles.navLinks}>
              Consult
            </Link>
            <Link to="/about" className={[styles.navLinks, styles.navLinksLast].join(' ')}>
              About Us
            </Link>
          </div>
    </div>
    <Outlet />
    </>
  );
}
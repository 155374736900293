import './addListing.css';
import PaginationButtonB from '../../components/paginationButtons/pagignationLeft';
import ToggleButtonGroup from '../../components/toggleButtonSet/toggleButton';
import ToggleButtonGroup2 from '../../components/toggleButtonSet/toggleButton2';
import '@fortawesome/fontawesome-free/css/all.css';
import React from 'react';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

const AddListing3 = () => {
  const navigate = useNavigate();

  const handleNextButtonClick = () => {
 
    navigate('/addListing4');
  
  };
    return (
      <div className="main-container"  >
        <div className="header-container">
              <PaginationButtonB />
              <p >Add Listing </p>
          </div>
          <div className='c-content' style={{marginTop:'100px', backgroundImage: 'url("/image/image.png")', backgroundSize: 'cover', backgroundPosition: 'contain', backgroundRepeat: 'repeat', backgroundSize: '100% 100%', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', overflow: 'hidden', background: 'rgba(255, 255, 255, 0.7)'  }}>
          <div>
            <p className='parr'>Hi, Give us details of your <strong>property</strong></p>
          </div>
          
          <div class="input-field">
            <FontAwesomeIcon icon={faHome} size="2x" className='house' />
            <input type="text" placeholder="property name e.g 'villas flats'" style={{fontSize:'small'}} />
            </div>
            <p className='parr'>Listing Type</p>
            <div>
            <ToggleButtonGroup />
            </div>
            <p className='parr'>Property category</p>
            <div>
            <ToggleButtonGroup2 />
            </div>
            </div>
  
        <div className="header-container2">
              <PaginationButtonB />
              <div className="button-container">
                  <button className="button" onClick={handleNextButtonClick}>Next</button>
              </div>
          </div>
      </div>
    );
  };
  
  export default AddListing3;
  
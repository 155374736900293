import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.css';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './profile.css';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  
  const profilePictureUrl = 'https://via.placeholder.com/150';

  
  const userName = 'Allick Nyerenda';
  const userEmail = 'allick@gmail.com';

  const numberOfListings = 40;

  //function for profile edit
  const handleEditProfilePicture = () => {
    //logic
  };
  const navigate = useNavigate();

  const handleNextButtonClick = () => {
 
    navigate('/addListing1');
  
  };

  return (
    <div className="profile-container">
      <div className="profile-content">
        <h1 style={{fontSize: 'large'}}>Profile</h1>
        <div className="profile-picture-container">
          <img src={profilePictureUrl} alt="Profile" className="profile-picture" style={{borderRadius:'50%'}} />
          <div className="edit-profile-button" onClick={handleEditProfilePicture}>
            <FontAwesomeIcon icon={faEdit} />
          </div>
        </div>
        <div className="user-details">
          <p>{userName}</p>
          <p>{userEmail}</p>
        </div>
        <div className="listing-count">
          <p style={{fontSize : 'medium'}}>{numberOfListings} Listings</p>
          <div>
            <button className="add-listing-button" onClick={handleNextButtonClick}>
            <FontAwesomeIcon icon={faPlus} style={{color:'grey'}} />
            </button>
          </div>
        </div>
        <div className="image-grid">
      <div class="image-row">
        <img src="image/couch.jpg" alt="Image 1" className="uploadpic"></img>
        <button className='ec' style={{backgroundColor: 'transparent'}}><FontAwesomeIcon icon={faEdit} style={{marginRight: '560px',height:'20px', width:'20px'}} /></button>
        <img src="image/house.jpg" alt="Image 2" className="uploadpic"></img>
        <button className='eb' style={{backgroundColor: 'transparent'}}><FontAwesomeIcon icon={faEdit} style={{marginLeft:'50px', marginTop:'0',height:'20px', width:'20px'}} /></button>
    </div>
  <div class="image-row">
        <button className='ec' style={{backgroundColor: 'transparent'}}><FontAwesomeIcon icon={faEdit} style={{marginRight: '560px',height:'20px', width:'20px'}} /></button>
        <img src="image/couch.jpg" alt="Image 3" className="uploadpic"></img>
        <button className='eb' style={{backgroundColor: 'transparent'}}><FontAwesomeIcon icon={faEdit} style={{marginLeft:'50px', marginTop:'0',height:'20px', width:'20px'}} /></button>
        <img src="image/house.jpg" alt="Image 4" className="uploadpic"></img>
        
  </div>
      </div>
      </div>
    </div>
  );
};

export default Profile;

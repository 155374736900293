import './addListing.css';
import PaginationButtonB from '../../components/paginationButtons/pagignationLeft';
import ToggleButtonGroup3 from '../../components/toggleButtonSet/toggleButton3';
import NumberSpinner from '../../components/numberSpinner/numberSpinner';
import '@fortawesome/fontawesome-free/css/all.css';
import NumberSpinner3 from '../../components/numberSpinner/numberSpinner3';
import NumberSpinner2 from '../../components/numberSpinner/numberSpinner2';
import ToggleButtonGroup4 from '../../components/toggleButtonSet/toggleButton4';
import React, { useState } from 'react';



const AddListing4 = () => {
  

    return (
      <div className="main-container"  >
        <div className='content-container' >

        <div className="header-container">
              <PaginationButtonB />
              <p >Add Listing </p>
          </div>
          
        
          <div className='c-content' style={{marginTop:'100px', backgroundImage: 'url("/image/image.png")', backgroundSize: 'cover', backgroundPosition: 'contain', backgroundRepeat: 'repeat', backgroundSize: '100% 100%', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', overflow: 'hidden', background: 'rgba(255, 255, 255, 0.7)'  }}  >
          <div>
            <p className='parr'><strong>Almost finish</strong>, Complete the listing</p>
          </div>
          <p className='parr'>Sell Price</p>
          
          <input type="text"  placeholder="ZMW" defaultValue="ZMW" style={{fontSize:'small', border: 'none',borderRadius:'15px', boxShadow: 'none', backgroundColor:'#eeeeee', height:'40px', width:'250px' ,  padding: '0 10px', outline: 'none'}} />
            <p className='parr'>Rent Price</p>
            
            <input type="text" placeholder="ZMW" defaultValue="ZMW" style={{fontSize:'small', border: 'none',borderRadius:'15px', boxShadow: 'none', backgroundColor:'#eeeeee', height:'40px', width:'250px',  padding: '0 10px', outline: 'none' }} />
           
            <ToggleButtonGroup3 />
            <p className='parr'>Property Features</p>
            <div className='spinner'>
            <div>
            <NumberSpinner />
            </div>
            <div>
            <NumberSpinner2 />
            </div>
            <div>
            <NumberSpinner3 />
            </div>
            <p className='parr'>Total Rooms</p>
            <div>
              <ToggleButtonGroup4 />
            </div>
            </div>
            


           
            </div>
            </div>
  
        <div className="header-container2">
              <PaginationButtonB />
              <div className="button-container">
                  <button className="button">Next</button>
              </div>
          </div>
          
      </div>
    );
  };
  
  export default AddListing4;
  